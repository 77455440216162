<template>
  <div class="UserCreatorMenu">
    <LargeButton :label="$locale['my_feed']" :class="`${mkLink(`/${$user.user}`, 'on')}`" @click="goToNav({ view: _view }, `/${$user.user}`)" />
    <LargeButton
      :label="$locale['my_subscribers']"
      :class="`${mkLink($links['my-subscribers'], 'on')}`"
      @click="goToNav({ view: _view }, $links['my-subscribers'])"
    />
    <LargeButton
      :label="$locale['statistics_label']"
      :class="`${mkLink($links['creator-statistics'], 'on')}`"
      @click="goToNav({ view: _view }, $links['creator-statistics'])"
    />
  </div>
</template>

<script>
export default {
  props: ["_view", "modal"],
};
</script>
